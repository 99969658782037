<script setup lang="ts">
import { Providers } from '@beacon/common/providers'
import { IndexRecord } from '@beacon/common/types'
import { ClipboardDocumentIcon } from '@heroicons/vue/20/solid'
import { asRelativeTime, asYYYYLLLDD, priceFull, setBrokenImageBlank } from '../utils/display'

type Props = {
  hit: IndexRecord
}
defineProps<Props>()

/**
 * Return the "secret" brochure id with the cloaked brochure type injected
 * @param id The brochure id
 * @param brand use 'a' for Ahoy, 'r' for Ray White, 'p' for plain.
 */
const generateBrochureIdForProvider = (id: string, brand: string): string => {
  const start = id.substring(0, 3)
  const end = id.substring(3, id.length)
  return `${start}${brand}${end}`
}

// Open the brochure in a new tab
const openBrochure = (brochureId: string, brand: string, target: string = '_blank') => {
  window.open(`https://sales.ahoyclub.com/listing/${generateBrochureIdForProvider(brochureId, brand)}`, target)
}

// Copy the brochure URL to the clipboard
const copyBrochureURL = async (brochureId: string, brand: string) => {
  const text = `https://sales.ahoyclub.com/listing/${generateBrochureIdForProvider(brochureId, brand)}`
  try {
    await navigator.clipboard.writeText(text)
  } catch ($e) {
    console.error('Failed to copy', $e)
  }
}

// List of the details fields to display.
const detailFields = [
  { label: 'Asking Price', has: () => true, toString: (h: IndexRecord) => priceFull(h.priceAsking) || '&mdash;' },
  { label: 'Model', has: () => true, toString: (h: IndexRecord) => h.model },
  { label: 'Builder', has: () => true, toString: (h: IndexRecord) => h.builder },
  { label: 'Build Year', has: () => true, toString: (h: IndexRecord) => h.buildYear },
  { label: 'Length (m)', has: () => true, toString: (h: IndexRecord) => h.length },
  { label: 'Name', has: (h: IndexRecord) => !!h.name, toString: (h: IndexRecord) => h.name },
  //
  { label: 'Beam (m)', has: (h: IndexRecord) => !!h.beam, toString: (h: IndexRecord) => h.beam },
  { label: 'Cabins', has: (h: IndexRecord) => !!h.cabins, toString: (h: IndexRecord) => h.cabins },
  { label: '# Decks', has: (h: IndexRecord) => !!h.decksCount, toString: (h: IndexRecord) => h.decksCount },
  { label: 'Draft', has: (h: IndexRecord) => !!h.draft, toString: (h: IndexRecord) => h.draft },
  { label: '# Engines', has: (h: IndexRecord) => !!h.engineCount, toString: (h: IndexRecord) => h.engineCount },
  { label: 'Engine Hours', has: (h: IndexRecord) => !!h.engineHours, toString: (h: IndexRecord) => h.engineHours },
  { label: 'Flag', has: (h: IndexRecord) => !!h.flag, toString: (h: IndexRecord) => h.flag },
  {
    label: 'Fuel Capacity (L)',
    has: (h: IndexRecord) => !!h.fuelCapacity,
    toString: (h: IndexRecord) => h.fuelCapacity,
  },
  { label: 'Refit Type', has: (h: IndexRecord) => !!h.refitType, toString: (h: IndexRecord) => h.refitType },
  { label: 'Refit Year', has: (h: IndexRecord) => !!h.refitYear, toString: (h: IndexRecord) => h.refitYear },
  {
    label: 'Crusing Speed (knots)',
    has: (h: IndexRecord) => !!h.speedCrusing,
    toString: (h: IndexRecord) => h.speedCrusing,
  },
  { label: 'Max Speed (knots)', has: (h: IndexRecord) => !!h.speedMax, toString: (h: IndexRecord) => h.speedMax },
  { label: 'Location', has: (h: IndexRecord) => !!h.location, toString: (h: IndexRecord) => h.location },
]
</script>
<template>
  <div class="w-full min-h-32 p-4 grid grid-cols-1 gap-y-4 bg-indigo-50">
    <!-- Detail Info Block -->
    <div class="w-full grid grid-cols-1 gap-y-4">
      <div class="w-full flex flex-auto">
        <!-- Detail fields -->
        <div class="basis-1/2 grow">
          <dl class="grid grid-cols-1 md:grid-cols-2 gap-4">
            <template v-for="detail in detailFields" :key="detail.label">
              <div v-if="detail.has(hit)" class="flex flex-nowrap gap-x-3">
                <dt class="font-semibold text-gray-600">{{ detail.label }}</dt>
                <dd class="text-gray-900" v-html="detail.toString(hit)"></dd>
              </div>
            </template>
          </dl>
        </div>

        <!-- Brochures -->
        <div class="basis-1/4 grow">
          <div class="grid grid-cols-1 gap-3">
            <span class="font-semibold">Brochures</span>
            <div class="flex gap-x-2">
              <button
                type="button"
                @click="openBrochure(hit.brochureId, 'a')"
                class="w-44 py-2 px-3 flex items-center justify-center text-sm font-medium text-center text-white bg-ahoy-club-blue rounded-lg hover:bg-ahoy-club-teal-secondary hover:text-ahoy-club-teal focus:ring-1 focus:outline-none focus:ring-ahoy-club-teal"
              >
                Ahoy Club
              </button>
              <button
                type="button"
                class="w-8 h-auto flex items-center justify-center text-white bg-ahoy-club-blue rounded-lg hover:bg-ahoy-club-teal-secondary hover:text-ahoy-club-teal"
                @click="[copyBrochureURL(hit.brochureId, 'a'), $emit('showCopySuccess')]"
              >
                <ClipboardDocumentIcon class="h-4 w-4 align-middle text-center" />
              </button>
            </div>

            <div class="flex gap-x-2">
              <button
                type="button"
                @click="openBrochure(hit.brochureId, 'r')"
                class="w-44 py-2 px-3 flex items-center justify-center text-sm font-medium text-center text-[#595959] bg-[#ffe512] rounded-lg hover:bg-gray-800 hover:text-white focus:ring-1 focus:outline-none focus:ring-[#ffe512]"
              >
                Ray White Marine
              </button>
              <button
                type="button"
                class="w-8 h-auto flex items-center justify-center text-[#595959] bg-[#ffe512] rounded-lg hover:bg-gray-800 hover:text-white focus:ring-1 focus:outline-none focus:ring-[#ffe512]"
                @click="[copyBrochureURL(hit.brochureId, 'r'), $emit('showCopySuccess')]"
              >
                <ClipboardDocumentIcon class="h-4 w-4 align-middle text-center" />
              </button>
            </div>
          </div>
        </div>

        <!-- Broker, Provider -->
        <div class="basis-1/4 grow">
          <div v-if="hit.broker" class="grid grid-cols-1 gap-2 pb-8">
            <span class="font-semibold">Broker</span>
            <div class="flex flex-col">
              <div class="font-medium">{{ hit.broker?.company }}</div>
              <div class="">{{ hit.broker?.name }}</div>
              <div class="">{{ hit.broker?.email }}</div>
              <div class="">{{ hit.broker?.phoneNumber }}</div>
            </div>
          </div>
          <div class="grid grid-cols-1 gap-2">
            <span class="font-semibold">Listing Provider</span>
            <div class="flex flex-wrap gap-8">
              <div class="grid grid-cols-1 gap-2">
                <dl class="grid grid-cols-1 gap-2">
                  <div class="flex flex-nowrap gap-x-3">
                    <dt class="font-semibold text-gray-600">Source</dt>
                    <dd class="text-gray-900 whitespace-nowrap">{{ Providers[hit.provider].name }}</dd>
                  </div>
                  <div class="flex flex-nowrap gap-x-3">
                    <dt class="font-semibold text-gray-600">ID</dt>
                    <dd class="text-gray-900">{{ hit.providerId }}</dd>
                  </div>
                </dl>
              </div>
              <div class="flex flex-col gap-1 whitespace-nowrap">
                <div class="text-xs text-gray-400">
                  Listing Checked: <span v-html="asRelativeTime(hit.tsChecked) || '&mdash;'"></span>
                </div>
                <div class="text-xs text-gray-400">
                  Listing Updated: <span v-html="asYYYYLLLDD(hit.tsListingUpdated) || '&mdash;'"></span>
                </div>
                <div class="text-xs text-gray-400">
                  Listing Published: <span v-html="asYYYYLLLDD(hit.tsListingPublished) || '&mdash;'"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- nunununnununununu -->
    <!-- *     Images    * -->
    <!-- nunununnununununu -->
    <ul class="flex flex-wrap w-full gap-2 justify-center">
      <li v-for="src in hit.images">
        <img
          class="rounded-lg w-auto h-64 object-contain md:hover:scale-150 duration-300"
          :src="`${src}`"
          loading="lazy"
          @error="setBrokenImageBlank"
          alt=""
        />
      </li>
      <li
        v-if="hit.images && hit.images.length != hit.imagesCount"
        class="w-64 h-64 bg-gray-200 rounded-md flex flex-col items-center justify-center"
      >
        <div class="text-center">
          <div class="text-5xl pb-4">+ {{ hit.imagesCount! - hit.images.length }}</div>
          <div>more in the brochure!</div>
        </div>
      </li>
    </ul>
  </div>
</template>
